<script setup>
import { ref } from 'vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/20/solid';

const frequencies = [
  { value: 'monthly', label: 'Monatlich', priceSuffix: '/Monat' },
  { value: 'yearly', label: 'Jährlich', priceSuffix: '/Jahr' }
];

const tiers = [
  {
    name: 'Personal',
    id: 'personal',
    price: { monthly: '5 €', yearly: '50 €' },
    description: 'Das perfekte Paket für Einzelpersonen.',
    features: ['1 Benutzer', '1 GB Speicherplatz', 'E-Mail Support'],
    mostPopular: false
  },
  {
    name: 'Teams',
    id: 'teams',
    price: { monthly: '250 €', yearly: '2500 €' },
    description: 'Ideal für kleine Teams und Startups.',
    features: [
      '10 Benutzer',
      '1 Team',
      '5 GB Speicherplatz',
      'E-Mail Support'
    ],
    mostPopular: true
  },
  {
    name: 'Enterprise',
    id: 'enterprise',
    price: null,
    description: 'Für Unternehmen oder Teams mit großen Datenmengen.',
    features: [
      'Unbegrenzte Benutzer',
      'Unbegrenzte Teams',
      '200 GB Speicherplatz',
      'Integrationen',
      'Anpassungen nach Bedarf',
      'priorisierter Support per E-Mail & Telefon'
    ],
    mostPopular: false
  }
];

const frequency = ref(frequencies[0]);

let paymentLink = function (tier, frequency, user) {
  if (!user || !user.id) {
    return route('login');
  }

  if (user.isSubscribed || user.is_admin) {
    return route('dashboard.index');
  }

  return route('stripe.subscription.checkout', { price: tier.id }) + (frequency.value === 'yearly' ? '-yearly' : '-monthly') + '-241015';
};
</script>

<template>
  <div class="bg-white" id="pricing">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <h2 class="text-base font-semibold leading-7 text-neutral-800">Preise</h2>
        <p class="mt-2 text-4xl font-bold tracking-tight text-neutral-800 sm:text-5xl">Ein passendes Paket<br />für
          alle größen von Teams</p>
      </div>

      <p class="mx-auto mt-6 max-w-2xl text-center text-lg text-neutral-600">
        Wählen Sie das Paket, das am besten zu Ihrem Unternehmen passt. Sie können jederzeit wechseln oder
        kündigen. Haben Sie Fragen oder
        benötigen etwas spezielles? Kontaktieren Sie uns gerne. </p>

      <div class="mt-16 flex justify-center">
        <div>
          <svg class="mr-2 h-6 w-6 text-success" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
            stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z">
            </path>
          </svg>
        </div>

        <div>
          <p class="mt-0.5 block text-success font-bold">Zwei Monate geschenkt bei jährlicher Zahlung.</p>
        </div>
      </div>

      <div class="mt-4 flex justify-center">
        <RadioGroup v-model="frequency"
          class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200">
          <RadioGroupLabel class="sr-only">Payment frequency</RadioGroupLabel>
          <RadioGroupOption as="template" v-for="option in frequencies" :key="option.value" :value="option"
            v-slot="{ checked }">
            <div
              :class="[checked ? 'bg-primary text-white' : 'text-gray-600', 'cursor-pointer rounded-full px-2.5 py-1']">
              <span>{{ option.label }}</span>
            </div>
          </RadioGroupOption>
        </RadioGroup>
      </div>

      <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div v-for="tier in tiers" :key="tier.id"
          :class="[tier.mostPopular ? 'ring-2 ring-primary' : 'ring-1 ring-gray-200', 'rounded-3xl p-8 xl:p-10']">
          <div class="flex items-center justify-between gap-x-4">
            <h3 :id="tier.id"
              :class="[tier.mostPopular ? 'text-primary' : 'text-neutral-800', 'text-lg font-semibold leading-8']">
              {{
                tier.name
              }}</h3>
            <p v-if="tier.mostPopular"
              class="rounded-full bg-cloud-50 px-2.5 py-1 text-xs font-semibold leading-5 text-primary">
              Most
              popular</p>
          </div>

          <p class="mt-4 text-sm leading-6 text-neutral-600 lg:min-h-14">{{ tier.description }}</p>

          <div class="mt-6">
            <p v-if="tier.price" class="flex items-baseline justify-center gap-x-1">
              <span class="text-4xl font-bold tracking-tight text-neutral-800">
                {{ tier.price[frequency.value] }}</span>
              <span class="text-sm font-semibold leading-6 text-neutral-600">{{ frequency.priceSuffix }}</span>
            </p>

            <p v-else class="text-xs font-semibold leading-5 text-center text-neutral-600">Kontaktieren Sie uns
              für<br />ein
              individuelles Angebot.</p>
          </div>

          <div class="flex items-center justify-center mt-2">
            <a v-if="tier.price" :href="paymentLink(tier, frequency, $page.props.auth.user)" :aria-describedby="tier.id"
              :class="[tier.mostPopular ? '' : 'btn-outline', 'btn btn-wide btn-primary']">Jetzt Kaufen</a>

            <a v-else :href="route('contact.index')" :aria-describedby="tier.id"
              class="btn btn-primary btn-wide">Kontakt aufnehmen</a>
          </div>

          <ul role="list" class="mt-8 space-y-3 text-sm leading-6 text-neutral-600 xl:mt-10">
            <li v-for="feature in tier.features" :key="feature" class="flex gap-x-3">
              <CheckIcon class="h-6 w-5 flex-none text-success" aria-hidden="true" />
              {{ feature }}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <p class="py-8 text-center text-xs leading-6 text-neutral-600">Die Kosten für die Nutzung von OpenAIs
          ChatGPT sind nicht enthalten. <a href="/#faq" class="link link-secondary"> Warum? </a>
        </p>
      </div>
    </div>
  </div>
</template>
